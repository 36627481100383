/*
 *
 * CookieConsent
 *
 * Simple mdule to handle closing of Cookie notification box when a user clicks `Accept`
 * Basic use -> CookieConsent.init('.box','.button');
 *
 * Aside from hiding/destroying the box when a user accepts,
 * we also need to set and check for a cookie when visiting the site.
 *
 */

const CookieConsent = {
	init(elem, trigger) {
		const box 	  	  = document.querySelector(elem);
		const btn 	  	  = document.querySelector(trigger);
		const destroy 	  = () => box.parentNode.removeChild(box);
		const writeCookie = () => {
			let now = new Date();
			now.setMonth(now.getMonth() + 1);
			document.cookie = "mt_cookie_consent=yes; expires=" + now.toUTCString() + "; path=/";
		}

		// Only run these functions if the `box` and `btn` elements exist
		if (box && btn) {

			// If Cookie already exists, we don't need to show the consent box
			if (document.cookie.indexOf("consent=") >= 0) {
				destroy();

				// If the Cookie doesn't exist, lets create one when the user clicks accept.
			} else {
				box.style.opacity = "1";

				btn.addEventListener("click", (event) => {
					// Disable default <a> functionality
					event.preventDefault();

					// Write the cookie
					writeCookie();

					// Fade element out
					box.style.opacity = "0";
					box.classList.add('consent-given');

					// Once element has faded out we will remove it from the DOM
					setTimeout(destroy, 500);
				});
			}
		}
	}
};

module.exports = CookieConsent;
