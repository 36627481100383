/*
 * Dinner Menu is our mobile navigation toggle
 *
 */

var doubleTapToGo = require('./doubleTapToGo.js');

$.fn.extend({

    dinnerMenu: function(options) {

        var config = {
            btn         : this,
            target      : $(this.attr('data-target')),
            activeClass : 'dinner-menu-active',
            transClass  : ' dinner-menu-transiting',
            header      : $('.js-dinner-menu-header'),
            parentEL    : $('html')
        };

        if (!config.target.length || !config.header.length) {
            return;
        } else {
            config.targetInner = config.target.children().wrapAll('<div class="dinner-menu__inner" />').parent();
        }

        var IS_FIXED = window.getComputedStyle(config.header[0]).position === 'fixed',
            isOpen  = false;

        if (IS_FIXED) {
            config.activeClass += ' dinner-menu-active--fixed';
        } else {
            config.activeClass += ' dinner-menu-active--relative';
        }

        function open(){
            config.btn.addClass(config.activeClass);
            config.parentEL.addClass( config.activeClass + config.transClass );
            config.target.css('paddingTop', config.header.outerHeight() );
            config.target[0].scrollTop = 0;
            isOpen = true;
        }

        function close(){
            config.parentEL.addClass( config.transClass );
            config.btn.removeClass(config.activeClass);
            config.parentEL.removeClass(config.activeClass);
            isOpen = false;
        }

        config.target.on('webkitTransitionEnd transitionend oTransitionEnd', function(e){
            config.parentEL.removeClass(config.transClass);
        });

        /*
         * Helper variable used to prevent
         * firing btn click event multiple times
         */
        var flag = false;

        config.btn.on('click touchstart', function(){

            if (!flag) {
                flag = true;
                setTimeout(function(){ flag = false; }, 100);
                ($(this).hasClass(config.activeClass) ) ? close() : open();
            }

            return false;

        });
    }
});

var MobileNav = {

    init: function(className) {

        // uses the dinner manu custom plugin
        $(className).dinnerMenu();

        // Mainly for Landscape tablets which still get the full menu
        $('.site-header nav li:has(ul)').doubleTapToGo();
    }
}

module.exports = MobileNav;
