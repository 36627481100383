/*
 * Gravity Form's AJAX submission removes the listeners in the form when submitted
 * Therefore we bind a listener to the outerwrapper and delegate
 */

var formController = {

    init: function() {
        try {

            $('form, .gform_wrapper').on('focus', 'input, textarea, select', function(e) {
                $(this).closest('.form__group, .gfield').addClass('focus');
            });

            $('form, .gform_wrapper').on('blur', 'input, textarea, select', function(e) {
                $(this).closest('.form__group, .gfield').removeClass('focus');
            });
        }
        catch(e) {}
    }
}

module.exports = formController;
