// Snazzy maps JSON created by Vivian
var mapStyles = [
    {
        "featureType": "water",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#b5cbe4"
            }
        ]
    },
    {
        "featureType": "landscape",
        "stylers": [
            {
                "color": "#efefef"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#83a5b0"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#bdcdd3"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e3eed3"
            }
        ]
    },
    {
        "featureType": "administrative",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": 33
            }
        ]
    },
    {
        "featureType": "road"
    },
    {
        "featureType": "poi.park",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
            {
                "lightness": 20
            }
        ]
    },
    // Hide
    {
        featureType: 'poi.business',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{visibility: 'off'}]
    }
]

$.fn.extend({

    googleMap: function(){

        var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        var el            = this,
            address       = this.data('address'),
            // markerImgSrc  = this.data('marker'),
            geocoder      = new google.maps.Geocoder(), // Create an instance of the google maps geocoder
            mapOptions    = {
                disableDefaultUI : true,
                center           : { lat: 49.2827, lng: -123.1207}, // Make sure to set a good fallback for the center
                zoom             : this.data('zoom') ? this.data('zoom') : 14,
                draggable        : isMobile ? false : this.data('draggable'),
                scrollwheel      : this.data('scrollwheel'),
                styles           : mapStyles
                
            },
            map           = new google.maps.Map( this[0] , mapOptions );

        // User's Location
        var locationMarkerIcon = {
            url: '/drive/themes/portalmakeafuture/assets/dist/img/location2.png',
            scaledSize: new google.maps.Size(52, 52)
        }
        marker = new google.maps.Marker({
            map: map,
            draggable: true,
            icon: locationMarkerIcon,
            position: {
              lat: 59.909144,
              lng: 10.7436936
            }
          });

        // Get Position
        infoWindow = new google.maps.InfoWindow;

        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            marker.setPosition(pos);
            marker.setTitle('Your position is '+(Math.round(pos.lat * 100) / 100)+", "+(Math.round(pos.lng * 100) / 100));
            map.setCenter(pos);
            }, function() {
            handleLocationError(true, infoWindow, map.getCenter());
            });
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, infoWindow, map.getCenter());
        }
        //END Get Position
        
        // this.getMarker = function(src) {

        //     var res    = null,
        //         marker = $(new Image());

        //     marker[0].src = src;

        //     marker
        //         .load(function(){

        //             var w = marker[0].width,
        //                 h = marker[0].height;

        //             res =  new google.maps.MarkerImage( src ,
        //                         new google.maps.Size(w, h), // size
        //                         new google.maps.Point(0, 0), // origin point
        //                         new google.maps.Point(w/2, h/2 )); // offset point

        //             el.trigger('googleMarkerLoaded', res );

        //         })
        //         .error(function(){
        //             el.trigger('googleMarkerLoaded', res );
        //         });


        // };

        // el.on('googleMarkerLoaded', function(e, mapMarker){

        // Address Location
        var markerIcon = {
            url: '/drive/themes/portalmakeafuture/assets/dist/img/pin2.png',
            size: new google.maps.Size(34, 46),
            scaledSize: new google.maps.Size(34, 46)
        }

        geocoder.geocode( { 'address': address}, function(results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                //In this case it creates a marker, but you can get the lat and lng from the location.LatLng
                map.setCenter(results[0].geometry.location);

                var marker = new google.maps.Marker({
                    map: map,
                    icon: markerIcon,
                    animation: google.maps.Animation.DROP,
                    position: results[0].geometry.location
                });
            }
        });

        // });

        // if (markerImgSrc) {
        //     this.getMarker(markerImgSrc);
        // } else {
        //     el.trigger('googleMarkerLoaded');
        // }
    }
});

var Maps = {

    init: function(className) {
        try {

            $(className).each(function(){
                $(this).googleMap();
            });
        }

        catch(e) {}
    }
}

module.exports = Maps;
