
var PostFilters = {

    init: function(){

    	if( typeof ajaxArgs === 'undefined' ){
    		return;
	    }

	    this.args = {
		    'post_type' : ajaxArgs.post_type,
		    'parent'    : ajaxArgs.parent,
		    'taxonomy'  : ajaxArgs.taxonomy,
		    'term'      : ajaxArgs.term,
		    'page'      : 1,
		    'filter'    : []
	    };

    	if( typeof ajaxArgs.s !== 'undefined' ){
    		this.args.s = ajaxArgs.s
	    }

    	this.getPosts = this.getPosts.bind(this);
    	this.addListeners();

		this.showLoader();
    	this.getPosts(this.args);
    },

	addListeners: function () {
		var self = this;


		$('.filters input').on('click', function() {
			self.showLoader();

			self.args.filter = [];

			$('input[type=checkbox]').each(function () {
				if( this.checked ) {
					self.args.filter.push( $(this).val() );
				}
			});

			self.getPosts(self.args);

		});


		$('.js-pagination').on('click', 'a', function (e) {
			e.preventDefault();

			self.showLoader();
			self.args.page = $(this).data('page');
			self.getPosts(self.args);
		});


		$('.js-btn-clear-filters').on('click', function (e) {
			e.preventDefault();

			self.showLoader();
			$('.filters form').trigger('reset');

			self.args.filter = [];
			self.getPosts(self.args);
		});

	},

	getPosts: function (args) {
		var self = this;

		$.when( self.queryPostsAjax(args) ).then(function( data, textStatus, jqXHR ) {
			$('.js-posts-feed').html('').append(data.data.posts);
			$('.js-pagination').html('').append(data.data.pagination);
			self.hideLoader();
		});
	},

	queryPostsAjax: function (args) {
		var self = this;

    	args.action = 'get_posts_feed_by_category';
    	// console.log( 'args', args );

		return $.ajax({
			url: ajax_object.ajax_url,
			type :'POST',
			data : args,
			success: function (result) {
				return result;
			},
			error: function(err){
				console.log(err);
			}
		});

	},

	showLoader: function() {
		this.postsLoader = $('.loader');

		this.postsLoader.addClass('loader--visible');
	},

	hideLoader: function() {
		this.postsLoader = $('.loader');

		this.postsLoader.removeClass('loader--visible');
	}

};

module.exports = PostFilters;
