/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 *
 * ======================================================================== */

window.$ = window.jQuery = require('jquery');

// Define Methods/Functions outside the router for reusability
var Broadcaster             = require('./modules/broadcaster.js'),
    Scroller                = require('./modules/scroller.js'),
    Sliders                 = require('./modules/Sliders.js'),
    MobileNav               = require('./modules/mobileNav.js'),
	Debounce                = require('./modules/debounce.js'),
	Collapser               = require('./modules/collapser.js'),
	Popups                  = require('./modules/popups.js'),
    ImagesLoaded            = require('./modules/imagesLoaded.js'),
	SiteSearch              = require('./modules/siteSearch.js'),
    CookieConsent           = require('./modules/cookieConsent.js');
    PostFilters             = require('./modules/postFilters.js');



/*
 * Use this variable to set up the common and page specific functions. If you
 * rename this variable, you will also need to rename the namespace below.
 */
var Router = {
    // All pages
    common: {

        init: function() {

            /*
			 * Javascript to be fired on every page
             * These can be deleted or refactored to the page where it is used
			 */

	        CookieConsent.init('.js-consent-box','.js-consent-btn');

            // Initialize common controllers
            // Sliders.init('.js-slider');
            MobileNav.init('.js-navigation-toggle');
            ImagesLoaded.init('.js-loaded');
			Collapser.init('.js-collapser-toggle');
            Popups.init('.js-popup');
            SiteSearch.init();

            // Animate On Scroll
            var AOS = require('aos');
            AOS.init({
                // Global settings:
                disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
                debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
                throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                offset: 120, // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                // mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

              });

            // CSS Wipe (Filler) Animation
            $('.to-animate-group').addClass('animated');

            // Sidebar category navigation
            $('.subcategories-trigger, .parent-cat__name').on('click', function(e){
                e.preventDefault();

                $(this).parent().toggleClass('is-active');

            });

            // Keep parent category open if subcategory is chosen (Desktop only)
            if ($(window).width() > 800 && $('.subcategories li').hasClass('current-cat')) {
                // console.log('current');
                $('.current-cat').closest('.parent-cat').addClass('is-active');
            } else {
                $('.current-cat').closest('.parent-cat').addClass('is-highlighted');
            }

        },

        // Finalize will get fired after all js in common.init and page specific js
        finalize: function() {
            Scroller.init();
        }
    },

    home: {
        init: function() {

            var AOS = require('aos');

            $('.home__featured').hide();
            $('.home').addClass('landing-visible');

            // Show Portal Landing Page once to user, then only show Featured Posts (until window is closed)
            if (sessionStorage.getItem('isClicked') === 'true'){
                $('.home').addClass('landing-hidden');
                // Used to hide categories on landing page on mobile
                $('.home').removeClass('landing-visible');

                // Have to use show/hide with AOS.refresh
                $('.home__landing').hide();
                $('.home__featured').show();
                AOS.refresh();
            }

            $('#js-enter').on('click',function(){
                $('.home').addClass('landing-hidden');
                // Used to hide categories on landing page on mobile
                $('.home').removeClass('landing-visible');

                // Have to use show/hide with AOS.refresh
                $('.home__landing').hide();
                $('.home__featured').show();
                AOS.refresh();

                sessionStorage.setItem('isClicked', $('.home').hasClass('landing-hidden'));
            });

        }
    },

	archive: {
    	init: function () {
            PostFilters.init();
	    }
    },

	blog: {
		init: function () {
			PostFilters.init();
		}
	},

    search: {
    	init: function () {
            PostFilters.init();
	    }
    },

    single: {
        init: function() {

            var SharePost = require('./modules/sharePost');
            SharePost.init();
        }
    },

	template_contact: {
		init: function(){

			var Maps      = require('./modules/maps.js'),
			    FormFocus = require('./modules/formFocus.js');

			Maps.init('.js-map');
            FormFocus.init();

            // Bring user back down to contact form if there are vaildation errors
            if ($('.gform_wrapper').hasClass('gform_validation_error')) {
                console.log('errors');
                $('html, body').animate({
                    scrollTop: $($('#contact-form')).offset().top - 70
                }, 500);
            }
		}
	}
};

/*
 * The routing fires all common scripts, followed by the page specific scripts.
 * Add additional events for more control over timing e.g. a finalize event
*/
var UTIL = {

    fire: function(func, funcname, args) {
        var namespace = Router;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function() {
        UTIL.fire('common');

        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
        });

        UTIL.fire('common', 'finalize');
    }

};

$(document).ready(UTIL.loadEvents);

