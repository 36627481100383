/*
 * Share Post
 * Opens a window from the share url on an anchor
 *
 */

var SharePost = {

    init: function(){

        $('.js-share-post').on('click', 'a', function(e){

            e.preventDefault();

            var $this = $(this),
                href  = $this.attr('href'),
                width       = 400,
                height      = 500,
                left        = (window.innerWidth/2) - width/2,
                top         = (window.innerHeight/2) - height/2,
                config      = 'status = no,height = '+width+',width  = '+height+',resizable = yes,screenY  = '+top+',screenX = '+left+',toolbar = no,menubar = no,scrollbars = no,location = no,directories = no';

            window.open(href, '', config);
        });
    }
}

module.exports = SharePost;
