var Broadcaster = require('./broadcaster.js'),
    Debounce    = require('./debounce.js');

var Scroller = {

    config: {
        scrollTop       : document.body.scrollTop || document.documentElement.scrollTop,
        lastScrollTop   : 0,
        scrollDirection : 'down',
		breakpoint      : 1024,
    },

    callScrollListener: function() {

            Scroller.config.scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

            var scrollTopIncreased = Scroller.config.scrollTop > Scroller.config.lastScrollTop;

            if (scrollTopIncreased) {
                Scroller.config.scrollDirection = 'down';
            } else {
                Scroller.config.scrollDirection = 'up';
            }

            Scroller.config.lastScrollTop = Scroller.config.scrollTop;

            // once values are updated, serve them to Broadcaster
            Scroller.serve();
    },

    bindUIActions: function(){

		// debounce the scroll trigger slightly
		var debouncedScroll = Debounce(function() {
			Scroller.callScrollListener();
		}, 15);

        $(window).off('scroll');
        $(window).on('scroll', function() {

			debouncedScroll();
        });
    },

    serve: function(){

        Broadcaster.publish('Scroller', {
            scrollTop:     Scroller.config.scrollTop,
            lastScrollTop: Scroller.config.lastScrollTop,
            direction:     Scroller.config.scrollDirection,
        });
    },

    init: function() {

        if ($(window).width() > Scroller.config.breakpoint) {

            $(window).on('load', function() {

                Scroller.bindUIActions();
            });
        }
    }
}

module.exports = Scroller;
