/*
 * Magnific popup initializer
 *
 * NOTE: make sure you are adding rel="nofollow" to any <a> tags that are
 * opening magnific popup to discourage search engines.
 * This could negatively affect SEO if forgotten.
 *
**/

require('magnific-popup');

var Popups = {

    setParams: function(popup, options) {

        var defaults =  {
            mainClass    : 'mfp-fade',
            removalDelay : 500,
        };

        return $.extend({}, defaults, options);
    },

    init: function(className, opts) {

        var $popups = $(className);

        $popups.each(function(){

            var popup = $(this);

            popup.magnificPopup( Popups.setParams(popup, opts) );
        });
    }
}

module.exports = Popups;
