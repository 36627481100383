/*
 * Module for initializing flexslider
 *
 */

var flexslider = require('flexslider');

var Sliders = {

    setParams: function(options, slider) {

        var defaults =  {
            namespace: 'slider-',
            prevText: '',
            nextText: '',
            animation: 'slide',
            manualControls: slider.next('.js-slider-nav').find('li'),

            start: function(sldr) {
                sldr.find('.js-loaded').imagesLoaded(function() {
                    $(this.elements).addClass('in');
                });
            }
        };

        return $.extend({}, defaults, options);
    },

    init: function(sliderClass, opts) {

        var sliders = $(sliderClass);

        sliders.each(function(){

            var slider = $(this);

            slider.flexslider(Sliders.setParams(opts, slider));

        });
    }
}

module.exports = Sliders;
